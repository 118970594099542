@import url('https://fonts.googleapis.com/css?family=Montserrat:500,700');

$link-color: #2185d0;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body, html {
  font-size: 16px;

  @media (min-width: 600px) {
    font-size: 18px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  margin-right: -1px;
  margin-left: -1px;
  padding-right: 1px;
  padding-left: 1px;

  padding-bottom: 1px;
  border-bottom: 3px solid rgba($link-color, .5);

  transition: color .15s ease;

  @media screen and (min-width: 660px) {
    padding-bottom: 1px;
    border-bottom: 3px solid rgba($link-color, .5);
  }

  &:hover,
  &:focus {
    color: $link-color;
  }
}
