@import url(https://fonts.googleapis.com/css?family=Montserrat:500,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth; }

* {
  box-sizing: border-box; }

body, html {
  font-size: 16px; }
  @media (min-width: 600px) {
    body, html {
      font-size: 18px; } }

a {
  color: inherit;
  text-decoration: none;
  margin-right: -1px;
  margin-left: -1px;
  padding-right: 1px;
  padding-left: 1px;
  padding-bottom: 1px;
  border-bottom: 3px solid rgba(33, 133, 208, 0.5);
  -webkit-transition: color .15s ease;
  transition: color .15s ease; }
  @media screen and (min-width: 660px) {
    a {
      padding-bottom: 1px;
      border-bottom: 3px solid rgba(33, 133, 208, 0.5); } }
  a:hover, a:focus {
    color: #2185d0; }

